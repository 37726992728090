import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext({ theme: 'dark', toggleTheme: () => {} });

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark'); // Default theme

  useEffect(() => {
    // Check for window to ensure this runs only in the browser
    if (typeof window !== 'undefined') {
      const storedTheme = localStorage.getItem('color-theme');
      if (storedTheme) {
        setTheme('dark');
      } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        setTheme('dark');
      }
    }
  }, []);

  useEffect(() => {
    document.documentElement.className = theme;
    localStorage.setItem('color-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'dark');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
